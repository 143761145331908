import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  // useMap,
} from "react-leaflet";
import useHasMounted from "../lib/useHasMounted";

export default () => {
  const hasMounted = useHasMounted();

  const isBrowser = typeof window !== "undefined"
  if (hasMounted && isBrowser) {
    return (
      <>
        <MapContainer style={{ height: 300 }} center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.505, -0.09]}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
      </>
    );
  } else {
    return <div>loading...</div>;
  }
};

